/* Custom button styles */
.custom-button {
  background-color: #ff7f50; /* Orange color for primary button */
  color: #fff; /* White text */
  border: none;
  border-radius: 25px; /* Rounded corners */
  padding: 10px 20px; /* Add some padding */
  font-size: 1rem; /* Adjust font size */
  font-weight: bold; /* Make text bold */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
  transition: background-color 0.3s, transform 0.2s; /* Smooth hover effect */
}

.custom-button:hover {
  background-color: #e67340; /* Slightly darker orange on hover */
}

.custom-button:active {
  transform: scale(0.95); /* Press effect */
}

.custom-button:disabled {
  background-color: #ffd6b5; /* Light orange for disabled state */
  cursor: not-allowed; /* Show not-allowed cursor for disabled button */
}
