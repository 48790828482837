/* LandingPage.css */

.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  background-color: #242424; /* Light background */
  padding: 20px;
}

.landing-content h1 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 20px;
}

.landing-content p {
  font-size: 1.2rem;
  color: #dbdbdb;
  margin-bottom: 20px;
}

.google-signin-button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 100px;
}

.google-signin-button:hover {
  background-color: #316ac5; /* Slightly darker shade */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle elevation */
}

.links {
  margin-top: 20px;
}

.links a {
  margin: 0 10px;
  text-decoration: none;
  color: #ffb8da;
}

.logo {
  font-size: 3rem; /* Adjust size as needed */
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(to right, #cc00ff, #ff007b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.background-container {
  position: fixed;
  top: -40px;
  left: -30px;
  justify-content: left;
  align-items: left;
  margin: 20px 0;
}

.logo-image {
  width: 120px;
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.3s ease; /* Add animation for hover */
}

.logo-image:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.landing-background-image {
  width: 610px;
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.3s ease; /* Add animation for hover */
}

.signin-buttons {
  margin-top: 100px;
}

.landing-page-footer {
  position: fixed; /* Fix it to the viewport */
  bottom: 20px; /* Align it to the bottom */
  left: 0; /* Align it to the left edge */
  width: 100%; /* Make it span the full width */
  padding: 10px 0; /* Add some spacing */
  text-align: center; /* Center align the links */
}
