.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #242424;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.nav-item {
  flex: 1; /* Ensure equal spacing for all links */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 20px;
}

.nav-item.active {
  background-color: #404040; /* Optional active background color */
  border-radius: 8px; /* Optional rounded effect */
}

.footer-link {
  text-align: center;
  color: #ff007b;
  text-decoration: none;
  font-size: 12px;
  transition: color 0.3s ease;
  width: 100%;
}

.footer-link:hover {
  color: #ffffff;
}

.footer-icon {
  margin-bottom: 4px;
  color: #ff007b;
}
