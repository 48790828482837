/* App.css */
.full-screen ~ .app-header,
.full-screen ~ .bottom-nav {
  display: none; /* Hide the header and footer */
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 91vw;
  height: 100vh;
  background-color: #242424; /* Background color for the full-screen view */
  overflow-y: auto; /* Allow scrolling if the content exceeds the viewport */
  z-index: 1000; /* Ensure it overlays other elements */
}

/* General layout */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Header */
.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #242424; /* White header */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.app-logo {
  height: 40px;
  width: auto;
  margin-right: 10px;
}

.app-title {
  font-family: "Yaldevi", sans-serif;
  font-size: 1.5rem;
  background: linear-gradient(to right, #cc00ff, #ff007b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

/* Main content */
.app-main {
  flex-grow: 1;
  padding: 8px;
  overflow-y: auto; /* Allow scrolling if content overflows */
  background: #242424;
}
