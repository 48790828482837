/* MyRecipes.css */

/* General page styling */
.favorites-page {
  font-family: Arial, sans-serif;
  background-color: #242424; /* Optional light background */
  padding-bottom: 60px;
}

.page-title {
  font-size: 1.75rem;
  margin-bottom: 20px;
  margin-left: 16px;
  text-align: left;
  color: #fff;
}

/* Grid layout for recipes */
.recipe-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(150px, 1fr)
  ); /* Min width of 150px per card */
  gap: 16px; /* Space between cards */
  padding: 8px;
}

/* Recipe card */
.recipe-card {
  background-color: #242424;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.recipe-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Recipe image */
.recipe-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
  background-color: #242424;
}

/* Recipe title */
.recipe-title {
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

/* Ingredient count */
.recipe-ingredients {
  font-size: 0.85rem;
  color: #777;
  margin-bottom: 10px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  background-color: #000000;
  border: 1px dashed #fff;
  border-radius: 8px;
  margin: 20px;
}

.empty-state h2 {
  font-size: 21px;
  margin-bottom: 10px;
}

.empty-state p {
  margin-bottom: 20px;
  color: #cccccc;
}

.empty-state .cta-button {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background-color: #ff007b;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.empty-state .cta-button:hover {
  background-color: #45a049;
}
