.profile-page {
  animation: fadeIn 0.5s ease-in-out;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #242424;
  border-radius: 12px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.profile-picture-container {
  margin: 0 auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #ff007b;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-name {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.profile-email {
  font-size: 16px;
  color: gray;
}

.profile-details {
  background: #242424;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.profile-details h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.profile-details p {
  margin: 5px 0;
  font-size: 16px;
}

.cta-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #e0e0e0;
  color: #666;
  border: none;
  border-radius: 6px;
  cursor: not-allowed;
}
